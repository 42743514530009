<template>
  <div>
    <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
      <nav class="navbar">
        <div class="navbar-brand">
          <a class="navbar-item" href="https://www.airbnb.fr">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg" alt="Airbnb Logo">
          </a>
          <div class="navbar-burger burger" @click="toggleNavbar" :class="{'is-active': isActive}">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="navbar-menu" :class="{'is-active': isActive}" style="margin-left: 25px">
          <div class="navbar-start centered-navbar-start">
            <div class="field has-addons" style="margin-top: 10px;width: 100%; max-width: 900px;">
              <p class="control">
                <a class="button is-static" style="background: white">
                  Destination
                </a>
              </p>
              <el-select
                v-model="destination"
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                style="width: 440px"
                size="large"
                @blur="onDestinationBlur"
              >
                <el-option
                  v-for="item in options"
                  :key="item.code"
                  :label="item.ville"
                  :value="item.ville"
                />
              </el-select>
              <el-date-picker
                ref="datePicker"
                style="margin-left: -10px;"
                v-model="datePeriode"
                type="daterange"
                range-separator="Au"
                start-placeholder="Date début"
                end-placeholder="Date Fin"
                size="large"
              />
            </div>
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <router-link class="navbar-item" href="#" to="/nouvelle_enregistrement">Ajouter ma résidence</router-link>
                <a class="button is-light">
                  Connexion
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccueilNavbar',
  data() {
    return {
      destination: '',
      datePeriode: [],
      isActive: false,
      options: [{
          ville: 'Abidjan',
          code: 'Tom'
        }, {
          ville: 'Assinie',
          code: 'Tom'
        }, {
          ville: 'Bassam',
          code: 'Tom'
        }, {
          ville: 'Bouaké',
          code: 'Tom'
        }],
    };
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
    onDestinationBlur() {
      if (this.destination) {
        this.$refs.datePicker.focus();
      }
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.navbar {
  top: 0;
  width: 100%;
  max-width: 1880px;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.navbar-item img {
  width: 200px;
}
.responsive-input {
  max-width: 500px;
  width: 100%;
}
.centered-navbar-start {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
