<template>
  <div>
    <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
      <nav class="navbar">
        <div style="width: 100%;">
          <div v-if="lala === 0">
            <el-row :gutter="24">
              <el-col :span="24" v-for="el in 5" :key="el">
                <el-skeleton style="width: 100%" :loading="loading" animated>
                  <template #template>
                    <el-skeleton-item variant="image" style="width: 100%; height: 240px" />
                    <div style="padding: 14px">
                      <el-skeleton-item variant="p" style="width: 80%" />
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-items: space-between;"
                      >
                        <el-skeleton-item variant="text" style="margin-right: 16px" />
                        <el-skeleton-item variant="text" style="width: 30%" />
                      </div>
                    </div>
                  </template>
                </el-skeleton>
              </el-col>
            </el-row>
          </div>
          <div v-else style="width: 100%; display: ruby">
            <div style="display: ruby">
              <div style="width: 94%; margin-left: 3%" v-for="item in msg" :key="item.id_item">
                <div class="image-item" style="width: 100%" @click="changeBiens(item)">
                  <!-- Utilisation de Swiper -->
                  <swiper :slides-per-view="swiperOptions.slidesPerView"
                  :space-between="swiperOptions.spaceBetween"
                  :pagination="swiperOptions.pagination"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                  style="height: 300px;">
                    <swiper-slide v-for="(image, index) in [item.image1, item.image2, item.image3, item.image4, item.image5]" :key="index">
                      <img height="300px" :src="image" :alt="'Property Image ' + (index + 1)">
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-pagination"></div> <!-- Pagination -->
                  <div class="tag">Résidence</div>
                </div>
                <div class="texte-zone">
                  <div class="top-row">
                    <p><b>{{item.ville_item}} | {{item.commune_item}}</b></p>
                    <p class="tarif"><b>{{item.tarif_jour}} FCFA</b></p>
                  </div>
                  <p style="color: gray">{{item.libelle}}</p>
                  <p><b>{{item.tarif_jour}} FCFA</b> / par nuit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'; // Importer Swiper et SwiperSlide depuis swiper/vue
import 'swiper/css'; // Importer les styles de Swiper

export default {
  name: 'AccueilNavbar',
  props: {
    msg: Array
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    biens() {
      return this.$store.state.donneesBiens;
    },
  },
  data() {
    return {
      lala: 0,
      loading: true,
      width: window.innerWidth,
      height: window.innerHeight,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      },
    };
  },
  methods: {
    skeleton() {
      setTimeout(() => {
        this.lala = 1;
      }, 2000);
    },

    updateDimensions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },

    changeBiens(bien) {
      this.$store.commit('setDonneesBien', bien);
      this.$router.push({ path: '/detail' });
    },
  },
  mounted() {
    this.skeleton();
    window.addEventListener('resize', this.updateDimensions);
  },
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.navbar {
  top: 0;
  width: 100%;
  padding: 10px;
  max-width: 1880px;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  font-size: 15px;
  color: black;
}

.image-item2 {
  position: relative; /* Position relative pour le positionnement absolu du tag */
  text-align: center; /* Centrer les images horizontalement */
  height: 200px; /* Hauteur fixe pour toutes les images */
  overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
  border-radius: 15px;
}

.image-item {
  position: relative; /* Position relative pour le positionnement absolu du tag */
  text-align: center; /* Centrer les images horizontalement */
  height: 300px; /* Hauteur fixe pour toutes les images */
  overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
  border-radius: 15px;
}

.image-item img {
  width: 100%; /* Assurez-vous que l'image occupe toute la largeur disponible */
  height: 300px; /* Ajuste automatiquement la hauteur pour maintenir le ratio d'aspect */
  object-fit: cover; /* Zoomer sur l'image tout en conservant le ratio d'aspect */
}

.tag {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #74716ba1;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  z-index: 10; /* Assurez-vous que le tag est au-dessus de l'image */
}

.texte-zone {
  text-align: left; /* Alignement du texte à gauche */
  padding-top: 10px;
  padding-bottom: 20px; /* Espacement interne */
}

.texte-zone p {
  margin: 5px 0;
  line-height: 1.2; /* Espacement entre les lignes de texte */
}

.texte-zone .top-row {
  display: flex;
  justify-content: space-between; /* Espace entre la ville/commune et le tarif */
  align-items: center;
}

.texte-zone .tarif {
  margin-left: auto; /* Pousse le tarif à droite */
}

.certification-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: green; /* Couleur de fond pour le tag */
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.el-carousel__button {
  width: 5px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
</style>

