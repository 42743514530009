<template>
    <div class="accueil_mobile_lbottom_bar">
      <div class="mobile-nav">
        <div class="container-mobile mb-2">
          <div class="mobile-group">
            <router-link
              :to="{ name: 'home', force: true }"
              class="mobile-widget mobile-active"
            >
              <i class="fa-solid fa-magnifying-glass icon"></i>
              <span>Recherche</span>
            </router-link>
            <router-link
              :to="{ name: 'home', force: true }"
              class="mobile-widget"
            >
              <i class="fa-solid fa-suitcase icon"></i>
              <span>Réservation</span>
            </router-link>
            <router-link
              :to="{ name: 'home', force: true }"
              class="mobile-widget plus-btn"
            >
              <i class="fa-solid fa-circle-plus fa-plus icon"></i>
              <span>Ajouter</span>
            </router-link>
            <span @click="ChoixDevise = true" class="mobile-widget">
              <i class="fa-solid fa-globe icon"></i>
              <span>Préférence</span>
            </span>
            <router-link
              :to="{ name: 'home', force: true }"
              class="mobile-widget"
            >
              <svg
                class="svg"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <circle
                    cx="12"
                    cy="9"
                    r="3"
                    stroke="#050a30"
                    stroke-width="2.5"
                  ></circle>
                  <path
                    d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
                    stroke="#050a30"
                    stroke-width="2.5"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                    stroke="#050a30"
                    stroke-width="2.5"
                    stroke-linecap="round"
                  ></path>
                </g>
              </svg>
              <span style="text-transform: capitalize !important">SAKONE</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BottomBar',
    data() {
      return {
        merci: '',
        ChoixDevise: false,
        // Ajoutez d'autres données si nécessaire
      };
    },
    methods: {},
    mounted() {},
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  .accueil_mobile_lbottom_bar {
    background-color: #fff;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .mobile-nav {
    display: flex;
    justify-content: center;
  }
  
  .container-mobile {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .mobile-group {
    padding: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .mobile-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #666;
    font-size: 12px
  }
  
  .mobile-active {
    color: #3273dc;
  }
  
  .icon {
    font-size: 1.2rem;
  }
  
  .svg {
    width: 24px;
    height: 24px;
  }
  </style>
  