import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    donneesBiens: [],
    listGrid: true,
    langueDevise: {
      langue: 'fr',
      devise: 'XOF'
    },
    uniquser: '',
    users: {
      nom: '',
      prenom: '',
      email: '',
      contact: '',
      adresse: '',
      photo: '',
      pays: '',
      role: '',
      statut: '',
      genre: '',
      email_recept: '',
      id: null,
    },
    formulaire: {
      hote: {
        nom_hote: null,
        contact1_hote: 0,
        contact2_hote: 0,
        email: '',
        texte_visiteur: '',
        photo: 'https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1'
      },
      images: {
        image1: 'http://res.cloudinary.com/r-sidence-meubl-e/image/upload/v1719916169/202462102999027.jpg',
        image2: 'a',
        image3: 'a',
        image4: 'a',
        image5: 'a',
        image6: 'a',
        image7: 'a',
        image8: 'a'
      },
      adresse: {
        ville: '',
        commune: '',
        adresse: ''
      },
      residence: {
        libelle: '',
        prix_nuit: '',
        type: '',
        reduction: 'Non',
        prix_reduction: '',
        duree_reduction: ''
      },
      services: {
        espace: false,
        restaurant: false,
        bar: false,
        petit_dej: false
      },
      service_icon: [],
      espace: [],
      restaurant: [],
      bar: [],
      petit_dej: [],
    },

    
    alert: {
      hote: {
        nom: false,
        contact1_hote: false,
        contact2_hote: false
      },
      residence: {
        libelle: false,
        prix_nuit: false,
        type: false
      },
      adresse: {
        ville: false,
        commune: false,
      },
      images: {
        image5: false,
        limite: false
      }
    }
  },
  getters: {
    // Exemple de getter pour accéder aux donnéesBiens
    getDonneesBiens: state => state.donneesBiens,
    getalert: state => state.alert,
  },
  mutations: {

    setLanguage(state, langue) {
      state.langueDevise.langue = langue;
    },

    Preferences: (state, data) => {
      state.langueDevise.langue = data.langue;
      state.langueDevise.devise = data.devise;
    },

    ConnexionAgent: (state, data) => {
      state.users.nom = data.nom;
      state.users.prenom = data.prenom;
      state.users.email = data.email;
      state.users.contact = data.contact;
      state.users.adresse = data.adresse;
      state.users.photo = data.photo;
      state.users.pays = data.pays;
      state.users.role = data.role;
      state.users.statut = data.statut;
      state.users.genre = data.genre;
      state.users.id = data.id;
      state.users.email_recept = data.email_recept;
      state.agent = 1;
      state.client = 0;
    },
    // Mutations pour mettre à jour donneesBiens
    setDonneesBien(state, bien) {
      state.donneesBiens = bien;
    },
    setalert(state, alert) {
      state.alert = alert;
    },
    setListGrid(state, etat) {
      state.listGrid = etat;
    },
  },
  actions: {
    // Exemple d'action pour mettre à jour donneesBiens
    updateDonneesBien({ commit }, bien) {
      commit('setDonneesBien', bien);
    },
  },
  plugins: [createPersistedState()], // Utilisation de vuex-persistedstate
  modules: {
    // Modules Vuex si nécessaire
  },
});
