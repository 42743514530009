import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Accueil
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  },

  {
    path: '/nouvelle_enregistrement',
    name: 'nouvelle_enregistrement',
    component: () => import('../views/dashboard/Formulaire.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
