<template>
    <div class="home">
        <div class="accueil_mobile_searchbar_component">
            <div class="navbarr">
				<div class="search-bar" @click="openActionSheet()">
					<i class="icon">&#x1F50D;</i>
					<input id="customInput" type="text" placeholder="Destination" :value="valeurRecherche" disabled>
				</div>
			</div>
        </div>

        <transition name="slide-up">
            <div class="actionsheet" v-if="isOpen">
                <!-- Arrière-plan semi-transparent -->
				<div div class="actionsheet-overlay" @click="closeActionSheet()"></div>
            
                <div class="actionsheet-content3 showLogin top-0" :style="{ 'max-height': maxHeight }">
					<div class="d-flex align-items-center dash-header bg-white mb-7">
						<vs-button @click="closeActionSheet()" radius color="dark" type="line" icon="close"></vs-button>
							<span style="flex: auto" class="">
							<h2 style="font-size: 18px" class="mb-0 text-center">
								Recherche
							</h2>
							</span>
					</div>
             
                    <div class="vstack gap-3 menu-account" style="background: #f5f5f6; padding: 15px; border-radius: 20px">
						<vs-row style="padding: 1px;">
							<vs-col v-tooltip="'col - 3'" vs-justify="center" vs-align="center" vs-w="12">
								<div style="display: flex;">
									<vs-select
										ref="secondInput"
										style="font-family: 'Jost', sans-serif"
										class="select-zone web-search"
										icon-after="true"
										icon="location_city"
										v-model="select1"
									>
										<vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
									</vs-select>
								</div>
							</vs-col>
							<vs-col v-tooltip="'col - 3'" vs-justify="center" vs-align="center" vs-w="6">
								<div style="display: flex;">
									<vs-select
										style="font-family: 'Jost', sans-serif"
										class="select-zone web-search"
										icon-after="true"
										icon="location_on"
										v-model="select2"
										:disabled="select2Grise"
									>
										<vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options2" />
									</vs-select>
								</div>
							</vs-col>
							<vs-col v-tooltip="'col - 3'" vs-justify="center" vs-align="center" vs-w="6">
								<div style="display: flex;">
									<vs-select
										style="font-family: 'Jost', sans-serif"
										class="select-zone web-search"
										icon-after="true"
										icon="meeting_room"
										v-model="selectedType"
									>
										<vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options3" />
									</vs-select>
								</div>
							</vs-col>
							
							<vs-col v-tooltip="'col - 3'" vs-justify="center" vs-align="center" vs-w="1">
							</vs-col>
							<vs-col v-tooltip="'col - 3'" vs-justify="center" vs-align="center" vs-w="1">
							</vs-col>
						</vs-row>
                    </div>
					<vs-divider/>
					<vs-row>
						<vs-col vs-type="flex" vs-w="6" style="padding: 10px 5px">
							<vs-checkbox v-model="checkBox2">
								<vs-chip>
									<vs-avatar icon="wifi"/>
									Wifi
								</vs-chip>
							</vs-checkbox>
						</vs-col>
						<vs-col vs-type="flex" vs-w="6" style="padding: 10px 5px">
							<vs-checkbox icon="sms" color="success" v-model="checkBox2">
								<vs-chip>
									<vs-avatar icon="pool"/>
									Piscine
								</vs-chip>
							</vs-checkbox>
						</vs-col>
						<vs-col vs-type="flex" vs-w="6" style="padding: 10px 5px">
							<vs-checkbox icon="sms" color="success" v-model="checkBox2">
								<vs-chip>
									<vs-avatar icon="sms"/>
									Bord de mer
								</vs-chip>
							</vs-checkbox>
						</vs-col>
						<vs-col vs-type="flex" vs-w="6" style="padding: 10px 5px">
							<vs-checkbox icon="sms" color="success" v-model="checkBox2">
								<vs-chip>
									<vs-avatar icon="sms"/>
									Bord de lagune
								</vs-chip>
							</vs-checkbox>
						</vs-col>
				</vs-row>
				<vs-divider/>
				<div class="cuadrox mb-4" style="background: #f5f5f6; padding: 8px"> 
						<p style="text-align: center;">XOF <span style="font-size: 20px; font-weight: bold">{{ value1[0]*5000 }}</span> - XOF <span style="font-size: 20px; font-weight: bold">{{ value1[1]*5000 }}</span></p>
				</div>
				<vs-slider style="width: 80%; margin-left: 5%" step=1 v-model="value1"/>
				<div class="d-flex justify-content-between mt-7 align-items-center mb-4">
				<span class="fw-bold text-decoration-underline">Tout effacer</span>
				<span class="btn btn-primary mb-0" type="filled" @click="closeActionSheet2()">
					<i class="bi bi-search float-left  me-3"></i>
					Rechercher
				</span>
				</div>
				
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            data: null,
            isOpen: false,
            select1: '0',
            select2: '0',
            selectedType: '0',
            checkBox2: false,
            select2Grise: false,
            value1:[1, 80],
            valeurRecherche: '',
            options3: [
				{ text: 'Tous Types', value: 0 },
				{ text: 'STUDIO', value: 'STUDIO' },
				{ text: '2 PIECES', value: '2 PIECES' },
				{ text: '3 PIECES', value: '3 PIECES' },
				{ text: '4 PIECES', value: '4 PIECES' },
				{ text: 'PLUS', value: 'PLUS' },
			],
            options2: [
				{ text: 'Zones', value: 0 },
				{ text: 'Abobo', value: 'Abobo' },
				{ text: 'Adjamé', value: 'Adjamé' },
				{ text: 'Angré', value: 'Angre' },
				{ text: 'Anyama', value: 'Anyama' },
				{ text: 'Attécoubé', value: 'Attécoubé' },
				{ text: 'Bingerville', value: 'Bingerville' },
				{ text: 'Cocody', value: 'Cocody' },
				{ text: 'II Plateau', value: 'II Plateau' },
				{ text: 'Koumassi', value: 'Koumassi' },
				{ text: 'Marcory', value: 'Marcory' },
				{ text: 'Plateau', value: 'Plateau' },
				{ text: 'Port-Bouet', value: 'Port-Bouet' },
				{ text: 'Riviera', value: 'Riviera' },
				{ text: 'Treichville', value: 'Treichville' },
				{ text: 'Yopougon', value: 'Yopougon' },
			],
            options1: [
				{ text: 'Ville', value: 0 },
				{ text: 'Abidjan', value: 'Abidjan' },
				{ text: 'Grand-Bassam', value: 'Grand-Bassam' },
				{ text: 'Yamoussoukro', value: 'Yamoussoukro' },
				{ text: 'Bouaké', value: 'Bouaké' },
			],
        }
    },
    filters: {
		formatThousands(value) {
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		},
	},
    watch: {
		AfficheZone() {
			document.querySelector('.hidee').style.display = 'block';
		},


		select2: function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		select1: function (newValue) {
			if (newValue != 'Abidjan') {
				this.select2 = 0;
				this.select2Grise = true;
			} else {
				this.select2Grise = false;
			}

			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		// Watcher pour le changement de selectedPrice
		selectedPrice: function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		// Watcher pour le changement de selectedType
		selectedType: function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		// Watcher pour le changement des filtres
		'filters.piscine': function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		'filters.restaurant': function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		'filters.bordureMer': function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		'filters.evenement': function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		},
		'filters.internet': function () {
			this.ListeFiltre(); // Appel de la fonction de recherche
		}
	},
    methods: {
		translate(prop) {
            return this[this.preference.langue][prop];
        },
        GlobalCloseSheet() {
            let action= false;
            this.$store.commit('ActionSheet', action);
            document.body.style.overflow = '';
        },

        handleFocus() {
			this.openActionSheet();
		},

		openActionSheet() {
				this.isOpen = true;
				console.log(this.isOpen);
				document.body.style.overflow = 'hidden';
        },

        closeActionSheet() {
			this.isOpen = false;
			console.log(this.isOpen)
			document.body.style.overflow = '';
		},

        closeActionSheet2() {
			const pays ="Côte d'Ivoire,";
			let ville = '';
			let commune = '';
			if(this.select1 == '0') {
				ville = ' Abidjan,';
			} else {
				ville = ' '+this.select1+',';
			}
			if(this.select2 == '0') {
				commune = '';
			} else {
				commune = ' '+this.select2+',';
			}
			this.valeurRecherche = pays+ville+commune;
			this.isOpen = false;
			document.body.style.overflow = '';
			this.ListeFiltre();
		},

    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.navbarr .logo {
	font-size: 18px;
	font-weight: bold;
	color: #FF5A5F;
	font-size: 20px;
    margin-left: 5px;
}
.navbarr .search-bar {
	flex: 1;
	display: flex;
	align-items: center;
	background-color: #f0f0f0;
	border-radius: 20px;
	padding: 1px 15px;
}
.navbarr .search-bar input {
	border: none;
	outline: none;
	background: none;
	flex: 1;
	padding: 12px;
	font-size: 16px;
	pointer-events: none;
}

.navbarr .search-bar .icon {
	margin-right: 10px;
	color: #888;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease-out;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>